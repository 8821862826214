import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography, Grid, Alert, Chip, Paper } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { LightTriggerIcon, DistanceTriggerIcon } from '@webapp/components/icons';
import { SensorProfile } from './sensor-profile-list';

interface SensorProfileTrainingProps {
  profile?: SensorProfile | null;
  onSave: (profile: Partial<SensorProfile>) => void;
  onBack: () => void;
  sensorType?: 'light' | 'distance';
}

const SensorProfileTraining: React.FC<SensorProfileTrainingProps> = ({
  profile,
  onSave,
  onBack,
  sensorType = 'light',
}) => {
  const SensorIcon = sensorType === 'light' ? LightTriggerIcon : DistanceTriggerIcon;
  const iconSize = sensorType === 'light' ? '1.5rem' : '1.8rem';
  const sensorTypeText = sensorType === 'light' ? 'Light' : 'Distance';
  const placeholderLabels = sensorType === 'light' ? 'Dark, Dim, Bright' : 'Close, Medium, Far';

  // Internal state management
  const [name, setName] = useState<string>(profile?.name || '');
  const [description, setDescription] = useState<string>(profile?.description || '');
  const [labels, setLabels] = useState<string>(profile?.labels?.join(', ') || placeholderLabels);
  const [nameError, setNameError] = useState<string>('');
  const [labelsError, setLabelsError] = useState<string>('');
  const [isTraining, setIsTraining] = useState<boolean>(false);
  const [trainingStep, setTrainingStep] = useState<number>(0);
  const [sampleCount, setSampleCount] = useState<number>(0);
  const [touched, setTouched] = useState<{ name: boolean; labels: boolean }>({ name: false, labels: false });

  // Update internal state when profile changes
  useEffect(() => {
    if (profile) {
      setName(profile.name || '');
      setDescription(profile.description || '');
      setLabels(profile.labels?.join(', ') || placeholderLabels);
    }
  }, [profile, placeholderLabels]);

  // Parse labels for preview
  const labelArray = labels
    .split(',')
    .map(label => label.trim())
    .filter(label => label !== '');

  // Validation
  useEffect(() => {
    // Only validate if the field has been touched
    if (touched.name) {
      // Validate name
      if (name.trim() === '') {
        setNameError('Profile name is required');
      } else if (name.length > 30) {
        setNameError('Profile name must be 30 characters or less');
      } else {
        setNameError('');
      }
    }

    // Only validate if the field has been touched
    if (touched.labels) {
      // Validate labels
      if (labelArray.length < 2) {
        setLabelsError('At least 2 states are required');
      } else if (labelArray.length > 5) {
        setLabelsError('Maximum 5 states are allowed');
      } else if (labelArray.some(label => label.length > 15)) {
        setLabelsError('Each state name must be 15 characters or less');
      } else {
        setLabelsError('');
      }
    }
  }, [name, labelArray, touched.name, touched.labels]);

  // Handle start training
  const handleStartTraining = () => {
    if (profile) {
      // If editing, just save the changes
      handleSaveProfile();
    } else {
      // If creating new, start training process
      setIsTraining(true);
      setTrainingStep(1);
      // Simulate training process
      const interval = setInterval(() => {
        setSampleCount(prev => {
          const newCount = prev + 5;
          if (newCount >= 100) {
            clearInterval(interval);
            setTrainingStep(2);
            return 100;
          }
          return newCount;
        });
      }, 500);
    }
  };

  // Handle finish training
  const handleFinishTraining = () => {
    handleSaveProfile();
    setIsTraining(false);
    setTrainingStep(0);
    setSampleCount(0);
  };

  // Handle save profile
  const handleSaveProfile = () => {
    if (!name.trim() || labelArray.length < 2) return;

    // Create profile object to save
    const profileData: Partial<SensorProfile> = {
      name,
      description,
      labels: labelArray,
    };

    // Call the onSave callback
    onSave(profileData);
  };

  // Check if form is valid
  const isFormValid = !nameError && !labelsError && name.trim() !== '';

  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <SensorIcon color="primary" sx={{ mr: 1, fontSize: iconSize }} />
        <Typography variant="h6">
          {profile ? `Edit ${sensorTypeText} Sensor Profile` : `Train New ${sensorTypeText} Sensor Profile`}
        </Typography>
      </Box>

      {!isTraining ? (
        // Profile configuration form
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Typography variant="subtitle2" sx={{ pt: 2 }}>
                Profile Name: <span style={{ color: 'red' }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder={`Enter ${sensorTypeText.toLowerCase()} profile name`}
                helperText={nameError || 'Give your profile a descriptive name'}
                value={name}
                onChange={e => setName(e.target.value)}
                onBlur={() => setTouched(prev => ({ ...prev, name: true }))}
                error={!!nameError}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography variant="subtitle2" sx={{ pt: 2 }}>
                Description:
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder={`Enter ${sensorTypeText.toLowerCase()} profile description`}
                helperText="Briefly describe what this profile is used for"
                value={description}
                onChange={e => setDescription(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={3}>
              <Typography variant="subtitle2" sx={{ pt: 2 }}>
                States: <span style={{ color: 'red' }}>*</span>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={9}>
              <TextField
                fullWidth
                variant="outlined"
                placeholder={placeholderLabels}
                helperText={labelsError || 'Enter 2-5 states separated by commas'}
                value={labels}
                onChange={e => setLabels(e.target.value)}
                onBlur={() => setTouched(prev => ({ ...prev, labels: true }))}
                error={!!labelsError}
              />

              {labelArray.length > 0 && (
                <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap' }}>
                  {labelArray.map((label, index) => (
                    <Chip
                      key={index}
                      label={label}
                      size="small"
                      color={index === 0 ? 'primary' : index === 1 ? 'warning' : index === 2 ? 'success' : 'default'}
                      sx={{ mr: 0.5, mb: 0.5 }}
                    />
                  ))}
                </Box>
              )}
            </Grid>
          </Grid>

          {!profile && (
            <Box sx={{ mt: 3, mb: 2 }}>
              <Typography variant="subtitle1" gutterBottom>
                Training Instructions
              </Typography>
              <Typography variant="body2" color="text.secondary">
                1. Enter a name and description for your profile
                <br />
                2. Define the {sensorTypeText.toLowerCase()} states you want to detect (e.g., {placeholderLabels})
                <br />
                3. Click &quot;Start Training&quot; to begin collecting samples
                <br />
                4. Follow the on-screen instructions to complete the training
              </Typography>
            </Box>
          )}
        </>
      ) : (
        // Training interface
        <Box sx={{ mt: 2 }}>
          <Paper elevation={1} sx={{ p: 3, mb: 3 }}>
            <Typography variant="h6" gutterBottom>
              {trainingStep === 1 ? 'Collecting Samples...' : 'Training Complete!'}
            </Typography>

            <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
              {trainingStep === 1
                ? `Place the ${sensorTypeText.toLowerCase()} sensor in different positions to collect samples for each state.`
                : `Your ${sensorTypeText.toLowerCase()} sensor profile has been trained successfully.`}
            </Typography>

            <Box sx={{ mb: 2 }}>
              <Typography variant="body2" gutterBottom>
                Progress: {sampleCount}%
              </Typography>
              <Box
                sx={{
                  height: '8px',
                  bgcolor: 'rgba(0,0,0,0.1)',
                  borderRadius: '4px',
                  overflow: 'hidden',
                }}
              >
                <Box
                  sx={{
                    height: '100%',
                    width: `${sampleCount}%`,
                    bgcolor: 'primary.main',
                    transition: 'width 0.3s ease-in-out',
                  }}
                />
              </Box>
            </Box>

            {trainingStep === 2 && (
              <Alert severity="success" sx={{ mb: 2 }}>
                Training complete with {labelArray.length} states and 100 samples. Estimated accuracy: 85-95%
              </Alert>
            )}
          </Paper>
        </Box>
      )}

      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
        <Button startIcon={<ArrowBackIcon />} onClick={onBack} disabled={isTraining && trainingStep === 1}>
          Back to Profiles
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={isTraining ? handleFinishTraining : handleStartTraining}
          disabled={!isTraining && !isFormValid}
        >
          {isTraining
            ? trainingStep === 1
              ? 'Training...'
              : 'Save Profile'
            : profile
              ? 'Save Changes'
              : 'Start Training'}
        </Button>
      </Box>
    </Box>
  );
};

export default SensorProfileTraining;
