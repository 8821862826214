import React, { useEffect } from 'react';
import { useAuth } from '@lib/auth';
import * as Sentry from '@sentry/browser';

export const SentryWrapper = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuth();

  useEffect(() => {
    // Skip setting user information when on localhost
    if (location.hostname === 'localhost' || location.hostname === '127.0.0.1') {
      return;
    }

    if (user) {
      Sentry.setUser({ id: user.id, email: user.email });
    } else {
      Sentry.setUser(null);
    }
  }, [user]);

  return children;
};
