import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Paper, Chip, CircularProgress, Grid, Card, CardContent } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { LightTriggerIcon, DistanceTriggerIcon } from '@webapp/components/icons';
import { SensorProfile } from './sensor-profile-list';

interface SensorProfileTestProps {
  profile: SensorProfile;
  onBack: () => void;
  sensorType?: 'light' | 'distance';
}

const SensorProfileTest: React.FC<SensorProfileTestProps> = ({ profile, onBack, sensorType = 'light' }) => {
  const SensorIcon = sensorType === 'light' ? LightTriggerIcon : DistanceTriggerIcon;
  const iconSize = sensorType === 'light' ? '1.5rem' : '1.8rem';
  const sensorTypeText = sensorType === 'light' ? 'Light' : 'Distance';

  // State for the test interface
  const [isConnecting, setIsConnecting] = useState<boolean>(false);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [currentReading, setCurrentReading] = useState<number>(0);
  const [currentState, setCurrentState] = useState<string>('');
  const [confidence, setConfidence] = useState<number>(0);
  const [testHistory, setTestHistory] = useState<
    Array<{
      timestamp: string;
      reading: number;
      state: string;
      confidence: number;
    }>
  >([]);

  // Connect to the sensor
  const handleConnect = () => {
    setIsConnecting(true);

    // Simulate connection delay
    setTimeout(() => {
      setIsConnecting(false);
      setIsConnected(true);
      startReadings();
    }, 1500);
  };

  // Disconnect from the sensor
  const handleDisconnect = () => {
    setIsConnected(false);
    setCurrentReading(0);
    setCurrentState('');
    setConfidence(0);
  };

  // Start simulated sensor readings
  const startReadings = () => {
    // Simulate sensor readings
    const interval = setInterval(() => {
      if (!isConnected) {
        clearInterval(interval);
        return;
      }

      // Generate random sensor reading based on sensor type
      const reading = sensorType === 'light' ? Math.floor(Math.random() * 1000) : Math.floor(Math.random() * 200);

      // Determine state based on reading
      let state = '';
      let stateConfidence = 0;

      if (sensorType === 'light') {
        if (reading < 200) {
          state = profile.labels[0] || 'Dark'; // First label or default
          stateConfidence = 0.85 + Math.random() * 0.15;
        } else if (reading < 600) {
          state = profile.labels[1] || 'Dim'; // Second label or default
          stateConfidence = 0.75 + Math.random() * 0.2;
        } else {
          state = profile.labels[2] || 'Bright'; // Third label or default
          stateConfidence = 0.8 + Math.random() * 0.18;
        }
      } else {
        // Distance sensor
        if (reading < 30) {
          state = profile.labels[0] || 'Very Close'; // First label or default
          stateConfidence = 0.9 + Math.random() * 0.1;
        } else if (reading < 70) {
          state = profile.labels[1] || 'Close'; // Second label or default
          stateConfidence = 0.8 + Math.random() * 0.15;
        } else if (reading < 120) {
          state = profile.labels[2] || 'Medium'; // Third label or default
          stateConfidence = 0.75 + Math.random() * 0.2;
        } else {
          state = profile.labels[3] || 'Far'; // Fourth label or default
          stateConfidence = 0.85 + Math.random() * 0.12;
        }
      }

      setCurrentReading(reading);
      setCurrentState(state);
      setConfidence(stateConfidence);

      // Add to history (limit to last 5 readings)
      const newReading = {
        timestamp: new Date().toLocaleTimeString(),
        reading,
        state,
        confidence: stateConfidence,
      };

      setTestHistory(prev => [newReading, ...prev].slice(0, 5));
    }, 2000); // Update every 2 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  };

  // Cleanup on unmount
  useEffect(() => {
    return () => {
      // Cleanup any resources
      setIsConnected(false);
    };
  }, []);

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
        <SensorIcon color="primary" sx={{ mr: 1, fontSize: iconSize }} />
        <Typography variant="h6">
          Testing {sensorTypeText} Sensor Profile: {profile.name}
        </Typography>
      </Box>

      <Paper elevation={2} sx={{ p: 3, mb: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <SensorIcon sx={{ fontSize: iconSize, mr: 1 }} />
          <Typography variant="h6">{profile.name}</Typography>
        </Box>

        <Typography variant="body1" sx={{ mb: 2 }}>
          {profile.description}
        </Typography>

        <Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 3 }}>
          <Typography variant="subtitle2" sx={{ mr: 1 }}>
            States:
          </Typography>
          {profile.labels.map((label, index) => (
            <Chip
              key={index}
              label={label}
              size="small"
              color={index === 0 ? 'primary' : index === 1 ? 'warning' : index === 2 ? 'success' : 'default'}
              sx={{ mr: 0.5, mb: 0.5 }}
            />
          ))}
        </Box>

        <Box sx={{ mb: 3 }}>
          <Typography variant="subtitle1" gutterBottom>
            Test Interface
          </Typography>

          {!isConnected ? (
            <Button
              variant="contained"
              color="primary"
              onClick={handleConnect}
              disabled={isConnecting}
              startIcon={isConnecting ? <CircularProgress size={20} /> : null}
            >
              {isConnecting ? 'Connecting...' : `Connect to ${sensorTypeText} Sensor`}
            </Button>
          ) : (
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Card variant="outlined" sx={{ mb: 2 }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      Current Reading
                    </Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <Box sx={{ position: 'relative', display: 'inline-flex', mr: 2 }}>
                        <CircularProgress
                          variant="determinate"
                          value={sensorType === 'light' ? currentReading / 10 : currentReading / 2}
                          color={
                            currentState === profile.labels[0]
                              ? 'primary'
                              : currentState === profile.labels[1]
                                ? 'warning'
                                : 'success'
                          }
                          size={80}
                          thickness={5}
                        />
                        <Box
                          sx={{
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            position: 'absolute',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography variant="caption" component="div" color="text.secondary">
                            {currentReading}
                          </Typography>
                        </Box>
                      </Box>

                      <Box>
                        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                          {currentState}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Confidence: {(confidence * 100).toFixed(0)}%
                        </Typography>
                      </Box>
                    </Box>

                    <Typography variant="caption" color="text.secondary">
                      {sensorType === 'light' ? 'Light level (0-1000)' : 'Distance in cm (0-200)'}
                    </Typography>
                  </CardContent>
                </Card>

                <Button variant="outlined" color="error" onClick={handleDisconnect}>
                  Disconnect
                </Button>
              </Grid>

              <Grid item xs={12} md={6}>
                <Typography variant="subtitle2" gutterBottom>
                  Reading History
                </Typography>

                {testHistory.length > 0 ? (
                  <Box sx={{ maxHeight: '200px', overflow: 'auto' }}>
                    {testHistory.map((item, index) => (
                      <Box
                        key={index}
                        sx={{
                          p: 1,
                          mb: 1,
                          borderRadius: 1,
                          bgcolor: 'rgba(0,0,0,0.03)',
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Box>
                          <Typography variant="body2">
                            {item.state} ({item.reading})
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            {item.timestamp}
                          </Typography>
                        </Box>
                        <Chip
                          label={`${(item.confidence * 100).toFixed(0)}%`}
                          size="small"
                          color={item.confidence > 0.9 ? 'success' : 'primary'}
                        />
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Typography variant="body2" color="text.secondary">
                    No readings yet
                  </Typography>
                )}
              </Grid>
            </Grid>
          )}
        </Box>
      </Paper>

      <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
        <Button startIcon={<ArrowBackIcon />} onClick={onBack}>
          Back to Profiles
        </Button>
      </Box>
    </Box>
  );
};

export default SensorProfileTest;
