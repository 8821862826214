import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import SensorProfileTraining from '../components/sensor-profile-training';
import SensorProfileTest from '../components/sensor-profile-test';
import SensorProfileList, { SensorProfile } from '../components/sensor-profile-list';

// Default profiles for demonstration
const defaultProfiles: SensorProfile[] = [
  {
    id: '1',
    name: 'Default Distance Profile',
    description: 'Default profile for distance sensor',
    labels: ['Close', 'Medium', 'Far'],
    sampleCount: 130,
    accuracy: 0.92,
    createdAt: '2023-01-15',
    updatedAt: '2023-01-15',
    isDefault: true,
  },
  {
    id: '2',
    name: 'Custom Distance Profile',
    description: 'Custom profile for precise distance detection',
    labels: ['Very Close', 'Close', 'Medium', 'Far', 'Very Far'],
    sampleCount: 110,
    accuracy: 0.85,
    createdAt: '2023-02-25',
    updatedAt: '2023-02-25',
  },
];

interface DistanceSensorProfilesTabProps {
  showTabs?: boolean;
  onShowTabsChange?: (show: boolean) => void;
  onBreadcrumbsChange?: (breadcrumbs: { label: string; path?: string }[]) => void;
  showTrainingPage?: boolean;
  onShowTrainingPageChange?: (show: boolean) => void;
}

const DistanceSensorProfilesTab: React.FC<DistanceSensorProfilesTabProps> = ({
  showTabs = true,
  onShowTabsChange = () => {
    /* No operation needed */
  },
  onBreadcrumbsChange = () => {
    /* No operation needed */
  },
  showTrainingPage: externalShowTrainingPage,
  onShowTrainingPageChange = () => {
    /* No operation needed */
  },
}) => {
  // State management
  const [profiles, setProfiles] = useState<SensorProfile[]>(defaultProfiles);
  const [showTrainingPage, setShowTrainingPage] = useState<boolean>(externalShowTrainingPage || false);
  const [showTestPage, setShowTestPage] = useState<boolean>(false);
  const [editingProfile, setEditingProfile] = useState<SensorProfile | null>(null);
  const [testingProfile, setTestingProfile] = useState<SensorProfile | null>(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState<boolean>(false);
  const [profileToDelete, setProfileToDelete] = useState<SensorProfile | null>(null);
  const [initialSetupDone, setInitialSetupDone] = useState<boolean>(false);

  // Sync with external showTrainingPage prop
  useEffect(() => {
    if (!initialSetupDone) {
      if (externalShowTrainingPage !== undefined) {
        setShowTrainingPage(externalShowTrainingPage);
      }
      setInitialSetupDone(true);
    }
  }, [externalShowTrainingPage, initialSetupDone]);

  // Effect to reset training page when showTabs changes
  useEffect(() => {
    if (showTabs) {
      setShowTrainingPage(false);
      setShowTestPage(false);
      setEditingProfile(null);
      setTestingProfile(null);
    }
  }, [showTabs]);

  // Handler functions
  const handleAddProfile = () => {
    setEditingProfile(null);
    setShowTrainingPage(true);
    onShowTrainingPageChange(true);
    onShowTabsChange(false);
    onBreadcrumbsChange([{ label: 'Distance Sensor Profiles' }, { label: 'Train New Profile' }]);
  };

  const handleEditProfile = (profile: SensorProfile) => {
    if (profile.isDefault) return; // Prevent editing default profiles

    setEditingProfile(profile);
    setShowTrainingPage(true);
    onShowTrainingPageChange(true);
    onShowTabsChange(false);
    onBreadcrumbsChange([{ label: 'Distance Sensor Profiles' }, { label: `Edit: ${profile.name}` }]);
  };

  const handleTestProfile = (profile: SensorProfile) => {
    setTestingProfile(profile);
    setShowTestPage(true);
    setShowTrainingPage(false);
    onShowTrainingPageChange(false);
    onShowTabsChange(false);
    onBreadcrumbsChange([{ label: 'Distance Sensor Profiles' }, { label: `Test: ${profile.name}` }]);
  };

  const handleDeleteClick = (profile: SensorProfile) => {
    if (profile.isDefault) return; // Prevent deleting default profiles

    setProfileToDelete(profile);
    setDeleteConfirmOpen(true);
  };

  const handleCancelDelete = () => {
    setDeleteConfirmOpen(false);
    setProfileToDelete(null);
  };

  const handleConfirmDelete = () => {
    if (profileToDelete) {
      setProfiles(profiles.filter(p => p.id !== profileToDelete.id));
    }
    setDeleteConfirmOpen(false);
    setProfileToDelete(null);
  };

  const handleBackToProfiles = () => {
    setShowTrainingPage(false);
    onShowTrainingPageChange(false);
    setEditingProfile(null);
    onShowTabsChange(true);
    onBreadcrumbsChange([{ label: 'Distance Sensor Profiles' }]);
  };

  const handleBackFromTest = () => {
    setShowTestPage(false);
    setTestingProfile(null);
    onShowTabsChange(true);
    onBreadcrumbsChange([{ label: 'Distance Sensor Profiles' }]);
  };

  const handleSaveProfile = (profileData: Partial<SensorProfile>) => {
    if (!profileData.name?.trim()) return;

    if (editingProfile) {
      // Update existing profile
      setProfiles(
        profiles.map(profile =>
          profile.id === editingProfile.id
            ? {
                ...profile,
                name: profileData.name || profile.name,
                description: profileData.description || profile.description,
                labels: profileData.labels || profile.labels,
                // In a real app, you'd update accuracy and sampleCount based on training
                accuracy: Math.random() * 0.3 + 0.7, // Simulate accuracy between 70-100%
                sampleCount: Math.floor(Math.random() * 50) + 100, // Simulate 100-150 samples
                updatedAt: new Date().toISOString().split('T')[0],
              }
            : profile
        )
      );
    } else {
      // Add new profile
      const newProfile: SensorProfile = {
        id: Date.now().toString(),
        name: profileData.name || '',
        description: profileData.description || '',
        labels: profileData.labels || [],
        sampleCount: Math.floor(Math.random() * 50) + 100, // Simulate 100-150 samples
        accuracy: Math.random() * 0.3 + 0.7, // Simulate accuracy between 70-100%
        createdAt: new Date().toISOString().split('T')[0],
        updatedAt: new Date().toISOString().split('T')[0],
      };
      setProfiles([...profiles, newProfile]);
    }

    setShowTrainingPage(false);
    onShowTrainingPageChange(false);
    onShowTabsChange(true);
    onBreadcrumbsChange([{ label: 'Distance Sensor Profiles' }]);
  };

  // Render based on current state
  if (showTrainingPage) {
    return (
      <Box id="distance-sensor-profiles-section">
        <SensorProfileTraining
          profile={editingProfile}
          onSave={handleSaveProfile}
          onBack={handleBackToProfiles}
          sensorType="distance"
        />
      </Box>
    );
  }

  if (showTestPage && testingProfile) {
    return (
      <Box id="distance-sensor-profiles-section">
        <SensorProfileTest profile={testingProfile} onBack={handleBackFromTest} sensorType="distance" />
      </Box>
    );
  }

  // Default view - show profile list
  return (
    <Box id="distance-sensor-profiles-section">
      <SensorProfileList
        profiles={profiles}
        sensorType="distance"
        onAddProfile={handleAddProfile}
        onEditProfile={handleEditProfile}
        onDeleteProfile={handleDeleteClick}
        onTestProfile={handleTestProfile}
        deleteConfirmOpen={deleteConfirmOpen}
        profileToDelete={profileToDelete}
        onCancelDelete={handleCancelDelete}
        onConfirmDelete={handleConfirmDelete}
      />
    </Box>
  );
};

export default DistanceSensorProfilesTab;
