import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, IconButton, Typography, Box, Breadcrumbs, Link } from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';
import SettingsContent from './settings-content';

interface SettingsModalProps {
  open: boolean;
  onClose: () => void;
  initialTab?: string;
  initialSection?: string;
}

const SettingsModal: React.FC<SettingsModalProps> = ({
  open,
  onClose,
  initialTab = 'light-sensor-profiles',
  initialSection,
}) => {
  const [breadcrumbs, setBreadcrumbs] = useState<{ label: string; path?: string }[]>([]);
  const [activeTab, setActiveTab] = useState<string>(initialTab);
  const [showTabs, setShowTabs] = useState<boolean>(true);
  const [showTrainingPage, setShowTrainingPage] = useState<boolean>(false);

  const handleBreadcrumbClick = (index: number) => {
    // Only navigate if it's not the last breadcrumb (current page)
    if (index < breadcrumbs.length - 1) {
      // If clicking on the first breadcrumb (tab name), navigate back to the tab
      if (index === 0) {
        // Update breadcrumbs to only include the first item
        setBreadcrumbs([breadcrumbs[0]]);

        // Set the active tab based on the breadcrumb label
        if (breadcrumbs[0]?.label === 'Light Sensor Profiles') {
          setActiveTab('light-sensor-profiles');
        } else if (breadcrumbs[0]?.label === 'Distance Sensor Profiles') {
          setActiveTab('distance-sensor-profiles');
        }

        // Show tabs and hide training page (same as "Back to Profiles" functionality)
        setShowTabs(true);
        setShowTrainingPage(false);
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" disableScrollLock={true}>
      <DialogTitle
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderBottom: '1px solid',
          borderColor: 'divider',
          p: 2,
        }}
      >
        <Box>
          <Breadcrumbs aria-label="breadcrumb" separator="›">
            {breadcrumbs.map((crumb, index) => {
              const isLast = index === breadcrumbs.length - 1;
              return isLast ? (
                <Typography key={index} color="text.primary" fontWeight="bold" variant="subtitle1">
                  {crumb.label}
                </Typography>
              ) : (
                <Link
                  key={index}
                  component="button"
                  underline="always"
                  color="inherit"
                  onClick={() => handleBreadcrumbClick(index)}
                  sx={{ cursor: 'pointer', fontWeight: 'medium' }}
                >
                  {crumb.label}
                </Link>
              );
            })}
          </Breadcrumbs>
        </Box>
        <IconButton edge="end" onClick={onClose} aria-label="close">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers sx={{ p: 0 }}>
        <SettingsContent
          initialTab={activeTab}
          initialSection={initialSection}
          breadcrumbs={breadcrumbs}
          onBreadcrumbsChange={setBreadcrumbs}
          onTabChange={setActiveTab}
          showTabs={showTabs}
          onShowTabsChange={setShowTabs}
          showTrainingPage={showTrainingPage}
          onShowTrainingPageChange={setShowTrainingPage}
        />
      </DialogContent>
    </Dialog>
  );
};

export default SettingsModal;
