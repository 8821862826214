import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import SettingsIcon from '@mui/icons-material/Settings';

interface SettingsButtonProps {
  onClick: () => void;
}

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.secondary.main,
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
  },
}));

const SettingsButton: React.FC<SettingsButtonProps> = ({ onClick }) => {
  return (
    <Tooltip title="Settings">
      <StyledIconButton
        onClick={onClick}
        size="small"
        aria-label="settings"
        sx={{
          ml: 1,
        }}
      >
        <SettingsIcon />
      </StyledIconButton>
    </Tooltip>
  );
};

export default SettingsButton;
