import React from 'react';
import { Box, IconButton, Typography, Chip, Card, CardContent } from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon, PlayArrow as PlayIcon } from '@mui/icons-material';
import { LightTriggerIcon, DistanceTriggerIcon } from '@webapp/components/icons';
import { SensorProfile } from './sensor-profile-list'; // Or move the interface to a shared types file

interface SensorProfileCardProps {
  profile: SensorProfile;
  sensorType: 'light' | 'distance';
  onEdit: (profile: SensorProfile) => void;
  onDelete: (profile: SensorProfile) => void;
  onTest: (profile: SensorProfile) => void;
}

const SensorProfileCard: React.FC<SensorProfileCardProps> = ({ profile, sensorType, onEdit, onDelete, onTest }) => {
  const SensorIcon = sensorType === 'light' ? LightTriggerIcon : DistanceTriggerIcon;

  // Format date - use updatedAt if available, otherwise use createdAt
  const dateToShow = profile.updatedAt || profile.createdAt;
  const formattedDate = new Date(dateToShow).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  return (
    <Card sx={{ position: 'relative' }} elevation={2}>
      <CardContent sx={{ pb: 2 }}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', mb: 1, flex: 1 }}>
            <SensorIcon color="primary" sx={{ mr: 1, fontSize: '48px', mt: 0.5 }} />
            <Box>
              <Typography variant="h6" component="div" sx={{ width: '300px' }}>
                {profile.name}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                Updated: {formattedDate}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: '0.75rem',
                color: 'text.secondary',
                ml: 2,
              }}
            >
              <span style={{ marginRight: '1rem' }}>Samples: {profile.sampleCount}</span>
              <span style={{ marginRight: '1rem' }}>Accuracy: {(profile.accuracy * 100).toFixed(0)}%</span>
              {profile.isDefault && (
                <Chip
                  label="Default"
                  size="small"
                  sx={{
                    bgcolor: 'rgba(0, 0, 0, 0.08)',
                    color: 'text.secondary',
                    height: '20px',
                    fontSize: '0.75rem',
                  }}
                />
              )}
            </Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
            {!profile.isDefault && (
              <>
                <IconButton size="small" onClick={() => onEdit(profile)}>
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton size="small" onClick={() => onDelete(profile)}>
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </>
            )}
            <IconButton
              size="small"
              onClick={() => onTest(profile)}
              color="primary"
              sx={{ ml: 0.5 }}
              title="Test profile"
            >
              <PlayIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>

        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
          {profile.description}
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle2" sx={{ mr: 1 }}>
            States:
          </Typography>
          <Box>
            {profile.labels.map((label, index) => (
              <Chip
                key={index}
                label={label}
                size="small"
                color={index === 1 ? 'warning' : 'default'}
                sx={{
                  mr: 0.5,
                  mb: 0.1,
                  bgcolor: index === 0 ? '#3498db' : index === 1 ? '#f39c12' : index === 2 ? '#2ecc71' : 'default',
                }}
              />
            ))}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default SensorProfileCard;
