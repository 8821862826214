import { createApi } from '@reduxjs/toolkit/query/react';
import protectedQuery from '@store/utils/protected-query';
import { AiProfileEntity, AiProfileStateEntity, AiProfileInput, AiProfileStateInput } from '@store/gql/graphql';

export const aiProfileApi = createApi({
  reducerPath: 'aiProfileApi',
  baseQuery: protectedQuery,
  tagTypes: ['AiProfile', 'AiProfileState'],

  endpoints: builder => ({
    // AI Profile endpoints
    getAiProfile: builder.query<AiProfileEntity, number>({
      query: (id: number) => `/ai-profiles/${id}?populate=user`,
      providesTags: (result, error, id) => [{ type: 'AiProfile', id }],
    }),

    getAiProfiles: builder.query<AiProfileEntity[], void>({
      query: () => ({
        url: '/ai-profiles',
        method: 'GET',
        params: {
          sort: 'updatedAt:desc',
          populate: ['user'],
        },
      }),
      providesTags: ['AiProfile'],
    }),

    createAiProfile: builder.mutation<AiProfileEntity, AiProfileInput>({
      query: (data: AiProfileInput) => ({
        url: '/ai-profiles',
        method: 'POST',
        body: { data },
      }),
      invalidatesTags: ['AiProfile'],
    }),

    updateAiProfile: builder.mutation<AiProfileEntity, { id: number; data: AiProfileInput }>({
      query: ({ id, data }: { id: number; data: AiProfileInput }) => ({
        url: `/ai-profiles/${id}`,
        method: 'PUT',
        body: { data },
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'AiProfile', id }, 'AiProfile'],
    }),

    deleteAiProfile: builder.mutation<AiProfileEntity, number>({
      query: (id: number) => ({
        url: `/ai-profiles/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['AiProfile'],
    }),

    // AI Profile State endpoints
    getAiProfileState: builder.query<AiProfileStateEntity, number>({
      query: (id: number) => `/ai-profile-states/${id}?populate=aiProfile`,
      providesTags: (result, error, id) => [{ type: 'AiProfileState', id }],
    }),

    getAiProfileStates: builder.query<AiProfileStateEntity[], void>({
      query: () => ({
        url: '/ai-profile-states',
        method: 'GET',
        params: {
          sort: 'updatedAt:desc',
          populate: ['aiProfile'],
        },
      }),
      providesTags: ['AiProfileState'],
    }),

    getProfileStates: builder.query<AiProfileStateEntity[], number>({
      query: (profileId: number) => ({
        url: `/ai-profile-states/profile/${profileId}`,
        method: 'GET',
      }),
      providesTags: (result, error, profileId) => [
        { type: 'AiProfileState', id: `profile-${profileId}` },
        'AiProfileState',
      ],
    }),

    createAiProfileState: builder.mutation<AiProfileStateEntity, AiProfileStateInput>({
      query: (data: AiProfileStateInput) => ({
        url: '/ai-profile-states',
        method: 'POST',
        body: { data },
      }),
      invalidatesTags: (result, error, data) => [
        'AiProfileState',
        { type: 'AiProfileState', id: `profile-${data.aiProfile}` },
      ],
    }),

    updateAiProfileState: builder.mutation<AiProfileStateEntity, { id: number; data: AiProfileStateInput }>({
      query: ({ id, data }: { id: number; data: AiProfileStateInput }) => ({
        url: `/ai-profile-states/${id}`,
        method: 'PUT',
        body: { data },
      }),
      invalidatesTags: (result, error, { id, data }) => [
        { type: 'AiProfileState', id },
        'AiProfileState',
        { type: 'AiProfileState', id: `profile-${data.aiProfile}` },
      ],
    }),

    deleteAiProfileState: builder.mutation<AiProfileStateEntity, { id: number; profileId: number }>({
      query: ({ id }: { id: number; profileId: number }) => ({
        url: `/ai-profile-states/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, { profileId }) => [
        'AiProfileState',
        { type: 'AiProfileState', id: `profile-${profileId}` },
      ],
    }),
  }),
});

export const {
  // AI Profile hooks
  useGetAiProfileQuery,
  useGetAiProfilesQuery,
  useCreateAiProfileMutation,
  useUpdateAiProfileMutation,
  useDeleteAiProfileMutation,

  // AI Profile State hooks
  useGetAiProfileStateQuery,
  useGetAiProfileStatesQuery,
  useGetProfileStatesQuery,
  useCreateAiProfileStateMutation,
  useUpdateAiProfileStateMutation,
  useDeleteAiProfileStateMutation,
} = aiProfileApi;
