import React, { useState, useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import SettingsTabs from './settings-tabs';
import LightSensorProfilesTab from './tabs/light-sensor-profiles-tab';
import DistanceSensorProfilesTab from './tabs/distance-sensor-profiles-tab';

interface SettingsContentProps {
  initialTab?: string;
  initialSection?: string;
  breadcrumbs: { label: string; path?: string }[];
  onBreadcrumbsChange: (breadcrumbs: { label: string; path?: string }[]) => void;
  onTabChange?: (tab: string) => void;
  showTabs?: boolean;
  onShowTabsChange?: (show: boolean) => void;
  showTrainingPage?: boolean;
  onShowTrainingPageChange?: (show: boolean) => void;
}

const SettingsContent: React.FC<SettingsContentProps> = ({
  initialTab = 'light-sensor-profiles',
  initialSection,
  breadcrumbs,
  onBreadcrumbsChange,
  onTabChange,
  showTabs = true,
  onShowTabsChange,
  showTrainingPage = false,
  onShowTrainingPageChange,
}) => {
  const [activeTab, setActiveTab] = useState<string>(initialTab);

  // Memoize handler functions to prevent unnecessary re-renders
  const handleShowTabsChange = useMemo(() => onShowTabsChange || (() => null), [onShowTabsChange]);
  const handleShowTrainingPageChange = useMemo(
    () => onShowTrainingPageChange || (() => null),
    [onShowTrainingPageChange]
  );
  const handleBreadcrumbsChange = useMemo(() => onBreadcrumbsChange || (() => null), [onBreadcrumbsChange]);

  // Update active tab when initialTab prop changes
  useEffect(() => {
    setActiveTab(initialTab);
  }, [initialTab]);

  // Set initial breadcrumbs
  useEffect(() => {
    if (activeTab === 'light-sensor-profiles') {
      handleBreadcrumbsChange([{ label: 'Light Sensor Profiles' }]);
    } else if (activeTab === 'distance-sensor-profiles') {
      handleBreadcrumbsChange([{ label: 'Distance Sensor Profiles' }]);
    }
  }, [activeTab, handleBreadcrumbsChange]);

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
    handleShowTabsChange(true);
    handleShowTrainingPageChange(false);

    if (tab === 'light-sensor-profiles') {
      handleBreadcrumbsChange([{ label: 'Light Sensor Profiles' }]);
    } else if (tab === 'distance-sensor-profiles') {
      handleBreadcrumbsChange([{ label: 'Distance Sensor Profiles' }]);
    }

    // Notify parent component about tab change
    if (onTabChange) {
      onTabChange(tab);
    }
  };

  return (
    <Box sx={{ p: 2 }}>
      {showTabs && <SettingsTabs activeTab={activeTab} onTabChange={handleTabChange} />}

      {activeTab === 'light-sensor-profiles' && (
        <LightSensorProfilesTab
          initialSection={initialSection}
          showTabs={showTabs}
          onShowTabsChange={handleShowTabsChange}
          breadcrumbs={breadcrumbs}
          onBreadcrumbsChange={handleBreadcrumbsChange}
          showTrainingPage={showTrainingPage}
          onShowTrainingPageChange={handleShowTrainingPageChange}
        />
      )}

      {activeTab === 'distance-sensor-profiles' && (
        <DistanceSensorProfilesTab
          initialSection={initialSection}
          showTabs={showTabs}
          onShowTabsChange={handleShowTabsChange}
          breadcrumbs={breadcrumbs}
          onBreadcrumbsChange={handleBreadcrumbsChange}
          showTrainingPage={showTrainingPage}
          onShowTrainingPageChange={handleShowTrainingPageChange}
        />
      )}
    </Box>
  );
};

export default SettingsContent;
