import React from 'react';
import { Box, Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Typography, Paper } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import SensorProfileCard from './sensor-profile-card';

export interface SensorProfile {
  id: string;
  name: string;
  description: string;
  labels: string[];
  sampleCount: number;
  accuracy: number;
  createdAt: string;
  updatedAt?: string;
  isDefault?: boolean;
}

interface SensorProfileListProps {
  profiles: SensorProfile[];
  sensorType: 'light' | 'distance';
  onAddProfile: () => void;
  onEditProfile: (profile: SensorProfile) => void;
  onDeleteProfile: (profile: SensorProfile) => void;
  onTestProfile: (profile: SensorProfile) => void;
  deleteConfirmOpen: boolean;
  profileToDelete: SensorProfile | null;
  onCancelDelete: () => void;
  onConfirmDelete: () => void;
}

const SensorProfileList: React.FC<SensorProfileListProps> = ({
  profiles,
  sensorType,
  onAddProfile,
  onEditProfile,
  onDeleteProfile,
  onTestProfile,
  deleteConfirmOpen,
  profileToDelete,
  onCancelDelete,
  onConfirmDelete,
}) => {
  const profileTypeText = sensorType === 'light' ? 'Light' : 'Distance';

  // Sort profiles by creation date (newest first)
  const sortedProfiles = [...profiles].sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  );

  return (
    <Box>
      {/* Profiles list */}
      <Grid container spacing={2}>
        {sortedProfiles.map(profile => (
          <Grid item xs={12} key={profile.id}>
            <SensorProfileCard
              profile={profile}
              sensorType={sensorType}
              onEdit={onEditProfile}
              onDelete={onDeleteProfile}
              onTest={onTestProfile}
            />
          </Grid>
        ))}

        {profiles.length === 0 && (
          <Grid item xs={12}>
            <Paper variant="outlined" sx={{ p: 3, textAlign: 'center' }}>
              <Typography variant="body2" color="text.secondary">
                No profiles available. Click the button below to create your first profile.
              </Typography>
            </Paper>
          </Grid>
        )}
      </Grid>

      {/* Add profile button */}
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3, mb: 1 }}>
        <Button
          variant="contained"
          color="primary"
          size="large"
          startIcon={<AddIcon />}
          onClick={onAddProfile}
          sx={{ px: 3, py: 1 }}
        >
          Train New {profileTypeText} Sensor Profile
        </Button>
      </Box>

      {/* Delete Confirmation Dialog */}
      <Dialog open={deleteConfirmOpen} onClose={onCancelDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete the profile &quot;{profileToDelete?.name}&quot;?</Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            This action cannot be undone.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancelDelete}>Cancel</Button>
          <Button onClick={onConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default SensorProfileList;
