import { Fragment } from 'react';
import { Toolbar, Grid, AppBar } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useRobo } from '@webapp/hooks/use-robo-hook';

import Toolbox from '@webapp/components/toolbox';
import NameProject from '@webapp/components/project-actions/name-project';
import { SettingsButton, SettingsModal } from '@webapp/components/settings';

import { PlayStopButton, BUTTON_TYPES } from './play-stop-button';
import { useEditor } from '@webapp/hooks/use-editor-hook';
import { useState } from 'react';
import { EditorType } from '@webapp/store/types/common.types';

const BottomNavBar = styled(AppBar)(() => ({
  margin: 'auto',
  boxShadow: 'none',
  position: 'fixed',
  backgroundColor: 'transparent',
  borderRadius: 'unset',
  top: 'auto',
  bottom: 0,
  width: '100%',
}));

const BottomBar = ({ editorType, handlePlayClick }) => {
  const { connected: isRoboConnected, store } = useRobo();
  const [settingsOpen, setSettingsOpen] = useState(false);

  const systemStore = store.model.systems?.['SYSTEM_1'];
  const batteryStatus = systemStore?.batteryStatus ?? null;

  const { isPlaying, playButtonIsBlocked } = useEditor(editorType);

  const hasActiveConnection = isRoboConnected;
  const isCharging = batteryStatus === 'charging' || batteryStatus === 'charged';

  const handleOpenSettings = () => {
    setSettingsOpen(true);
  };

  const handleCloseSettings = () => {
    setSettingsOpen(false);
  };

  // Only show settings in code editor
  const showSettings = editorType === EditorType.Code;

  return (
    <BottomNavBar className="toolbox-bar">
      <Toolbar>
        <Grid container spacing={0}>
          <Grid
            item
            xs={3}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              marginTop: '-40px',
            }}
          >
            {!isPlaying && (
              <Fragment>
                <NameProject />
              </Fragment>
            )}
          </Grid>

          <Grid
            item
            xs={1}
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              paddingTop: '55px',
            }}
          >
            {showSettings && <SettingsButton onClick={handleOpenSettings} />}
          </Grid>

          <Grid item xs={6} sx={{ position: 'relative' }}>
            {!isPlaying && <Toolbox editor={editorType} />}
          </Grid>

          <Grid
            item
            xs={2}
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <PlayStopButton
              type={isPlaying ? BUTTON_TYPES.stop : BUTTON_TYPES.play}
              disabled={!hasActiveConnection || playButtonIsBlocked || isCharging}
              tooltip={isCharging ? 'Play mode is disabled during charge' : null}
              onClick={handlePlayClick}
            />
          </Grid>
        </Grid>
      </Toolbar>

      {showSettings && (
        <SettingsModal open={settingsOpen} onClose={handleCloseSettings} initialTab="light-sensor-profiles" />
      )}
    </BottomNavBar>
  );
};

export default BottomBar;
