import React from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { LightTriggerIcon, DistanceTriggerIcon } from '@webapp/components/icons';

interface SettingsTabsProps {
  activeTab: string;
  onTabChange: (tab: string) => void;
}

const SettingsTabs: React.FC<SettingsTabsProps> = ({ activeTab, onTabChange }) => {
  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    onTabChange(newValue);
  };

  return (
    <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        aria-label="settings tabs"
        variant="scrollable"
        scrollButtons="auto"
      >
        <Tab
          icon={<LightTriggerIcon />}
          iconPosition="start"
          label="Light Sensor Profiles"
          value="light-sensor-profiles"
          sx={{
            minHeight: '48px',
            textTransform: 'none',
            '& .MuiSvgIcon-root': {
              fontSize: '1.5rem',
              mr: 1,
            },
          }}
        />
        <Tab
          icon={<DistanceTriggerIcon />}
          iconPosition="start"
          label="Distance Sensor Profiles"
          value="distance-sensor-profiles"
          sx={{
            minHeight: '48px',
            textTransform: 'none',
            '& .MuiSvgIcon-root': {
              fontSize: '1.8rem',
              mr: 1,
            },
          }}
        />
      </Tabs>
    </Box>
  );
};

export default SettingsTabs;
