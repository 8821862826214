import { convertHexToStringRepresentation } from '@lib/utils';
import { RoboClient } from '@lib/robo/robo-client';
import { AvailableModuleInstance, RoboModel } from '@lib/robo/robo-model';

import { HandlerType, ModulesCollectionTypes, Command, ModuleId } from './types';

export const BATCH_SENSORS_COMMANDS: {
  [key in ModulesCollectionTypes]?: {
    command: Command;
    commandLength: number;
  };
} = {
  lightSensors: { command: Command.CMD_LIGHT_LEVEL, commandLength: 2 }, // 0x80
  motions: { command: Command.CMD_MOTION_DET, commandLength: 1 }, // 0x83
  ultrasonics: { command: Command.CMD_GET_DISTANCE, commandLength: 2 }, // 0x84
  buttons: { command: Command.CMD_GET_BUTTON, commandLength: 1 }, // 0x85
  linetrackers: { command: Command.CMD_GET_LINETRACKER, commandLength: 1 }, // 0x86
  accelerometers: { command: Command.CMD_GET_ACC, commandLength: 6 }, // 0x89
};

const sensorModulesEventsMap: {
  [key in ModulesCollectionTypes]?: HandlerType;
} = {
  lightSensors: HandlerType.OnLightLevel,
  linetrackers: HandlerType.OnLinetracker,
  buttons: HandlerType.OnButton,
  ultrasonics: HandlerType.OnProximity,
  motions: HandlerType.OnMotionDetector,
  accelerometers: HandlerType.OnAccelerometer,
};

type SensorEventData = {
  moduleType: ModulesCollectionTypes;
  moduleIndex: number;
  data: Uint8Array;
  executionTime: number;
};

const BATCH_REQUEST_SIZE = 16;
const BATCH_SENSORS_COMMANDS_BY_COMMAND_CODE: {
  [key: string]: {
    command: number;
    commandLength: number;
    moduleType: ModulesCollectionTypes;
  };
} = {};

(Object.keys(BATCH_SENSORS_COMMANDS) as ModulesCollectionTypes[]).forEach(moduleType => {
  const commandProps = BATCH_SENSORS_COMMANDS[moduleType];

  if (!commandProps) {
    return;
  }

  const hexCode = convertHexToStringRepresentation(commandProps.command);

  if (!hexCode) {
    return;
  }

  BATCH_SENSORS_COMMANDS_BY_COMMAND_CODE[hexCode] = {
    ...commandProps,
    moduleType,
  };
});

export class BatchSensorsFetcher {
  private checkIntervalDuration: number;
  private checkIntervalId: null | ReturnType<typeof setInterval>;
  private lastBatchIndex: number;
  private client: RoboClient;
  private sentDataMap: Map<
    number,
    {
      data: Uint8Array;
      timestamp: number;
    }
  >;
  private parsedBatchResponseMap: Map<
    number,
    Array<{
      moduleType: ModulesCollectionTypes;
      moduleIndex: number;
      data: Uint8Array;
      executionTime: number;
    }>
  >;
  private hasPendingRequest: boolean;
  private lastRequestTime: number;

  constructor(
    client: RoboClient,
    options: {
      checkTimeout: number | null;
    }
  ) {
    this.client = client;
    this.sentDataMap = new Map();
    this.parsedBatchResponseMap = new Map();
    this.lastBatchIndex = 0;
    this.hasPendingRequest = false;
    this.lastRequestTime = 0;

    this.checkIntervalDuration = options.checkTimeout ?? 300;
    this.checkIntervalId = null;

    this.client.registerBroadcastHandler(HandlerType.OnBatchSensors, this.handleBatchSensorsResponse.bind(this));
  }

  start(modulesIds: ModuleId[] | null = null) {
    this.runBatchSensorsCheck(modulesIds);
  }

  stop() {
    this.stopBatchSensorsCheck();
    this.clearParsedBatchResponse();
  }

  isRunning(): boolean {
    return this.checkIntervalId !== null;
  }

  clearParsedBatchResponse() {
    this.parsedBatchResponseMap.clear();
  }

  generateNextBatchIndex() {
    this.lastBatchIndex += 1;
    if (this.lastBatchIndex >= 255) {
      this.lastBatchIndex = 0;
    }

    return this.lastBatchIndex;
  }

  createFulfilledBatch(batch: Array<number>) {
    const index = this.generateNextBatchIndex();
    const data = new Uint8Array(batch);
    this.sentDataMap.set(index, { data, timestamp: Date.now() });

    return {
      index,
      data,
    };
  }

  createBatchRequestsForSensors(
    sensors: Array<{
      type: ModulesCollectionTypes;
      index: number;
    }>
  ) {
    let currentBatchLength = 0;
    let batchCommand: Array<number> = [];
    const createdBatches = [];

    for (const sensor of sensors) {
      if (!BATCH_SENSORS_COMMANDS[sensor.type]) {
        continue;
      }

      const commandProps = BATCH_SENSORS_COMMANDS[sensor.type];

      if (commandProps === undefined) {
        continue;
      }

      const { command, commandLength } = commandProps;

      if (currentBatchLength + commandLength <= BATCH_REQUEST_SIZE) {
        batchCommand.push(command, sensor.index);
        currentBatchLength += commandLength;
      } else {
        // Check if it's greater than BATCH_REQUEST_SIZE, then split the batch
        if (currentBatchLength + commandLength > BATCH_REQUEST_SIZE) {
          createdBatches.push(this.createFulfilledBatch(batchCommand)); // Create a batch if the length exceeds BATCH_REQUEST_SIZE
        }

        // Create a new batch with the current command
        batchCommand = [command, sensor.index];
        currentBatchLength = commandLength;
      }
    }

    if (batchCommand.length > 0) {
      createdBatches.push(this.createFulfilledBatch(batchCommand));
    }

    return createdBatches;
  }

  parseBatchSensorsResponse(batchIndex: number, responseData: Uint8Array) {
    const sentRequest = this.sentDataMap.get(batchIndex);
    if (!sentRequest) {
      return null;
    }

    const responses = [];
    let cursor = 0;
    const sentRequestData = sentRequest.data;

    for (let i = 0; i < sentRequestData.length; i += 2) {
      const sensorCommand = convertHexToStringRepresentation(sentRequestData[i]);
      const moduleIndex = sentRequestData[i + 1];

      if (!BATCH_SENSORS_COMMANDS_BY_COMMAND_CODE[sensorCommand]) {
        continue;
      }

      const { moduleType, commandLength } = BATCH_SENSORS_COMMANDS_BY_COMMAND_CODE[sensorCommand];
      const batchResponseData = responseData.slice(cursor, cursor + commandLength);
      cursor += commandLength;

      responses.push({
        moduleType,
        moduleIndex,
        data: batchResponseData,
        executionTime: Date.now() - sentRequest.timestamp,
      });
    }

    this.parsedBatchResponseMap.set(batchIndex, responses);

    return responses;
  }

  handleBatchSensorsResponse(payload: { index: number; data: Uint8Array }) {
    const batchResponse = this.parseBatchSensorsResponse(payload.index, payload.data);

    if (!batchResponse) {
      return null;
    }

    for (const { moduleType, moduleIndex, data, executionTime } of batchResponse) {
      const eventName = sensorModulesEventsMap[moduleType];

      this.client.invokeHandlers(
        eventName ?? HandlerType.OnSensor,
        {
          moduleType,
          moduleIndex,
          data,
          executionTime,
        } as SensorEventData,
        RoboModel.getModuleId(moduleType as ModulesCollectionTypes, moduleIndex)
      );
    }

    // Mark that we've received the response
    this.hasPendingRequest = false;
    this.lastRequestTime = Date.now();
  }

  runBatchSensorsCheck(modulesIds: ModuleId[] | null) {
    if (this.checkIntervalId) {
      throw Error('Batch sensors check has already started. Stop it before call runBatchSensorsCheck');
    }
    this.checkIntervalId = setInterval(() => {
      if (!this.client.model || !this.client.model.modules) {
        return;
      }

      // Don't send new requests if we have a pending one
      if (this.hasPendingRequest) {
        return;
      }

      // Don't send new requests if not enough time has passed since the last request
      const now = Date.now();
      if (now - this.lastRequestTime < this.checkIntervalDuration) {
        return;
      }

      const sensorsForRequest = [];

      for (const [moduleType, modules] of Object.entries(this.client.model.modules)) {
        if (BatchSensorsFetcher.isModuleSensor(moduleType as ModulesCollectionTypes)) {
          let sensorModules = Object.values(modules) as AvailableModuleInstance[];

          if (modulesIds !== null) {
            sensorModules = sensorModules.filter(module => modulesIds.includes(module.id as ModuleId));
          }

          sensorsForRequest.push(
            ...sensorModules.map(module => ({
              index: module.index,
              type: module.type,
            }))
          );
        }
      }

      const requests = this.createBatchRequestsForSensors(sensorsForRequest);
      if (requests.length > 0) {
        this.hasPendingRequest = true;
        for (const { index, data } of requests) {
          this.client.sendBatchSensorsRequest(index, data);
        }
      }
    }, this.checkIntervalDuration);
  }

  stopBatchSensorsCheck() {
    if (this.checkIntervalId !== null) {
      clearInterval(this.checkIntervalId);
      this.checkIntervalId = null;
    }
  }

  static isModuleSensor(moduleType: ModulesCollectionTypes) {
    return !!BATCH_SENSORS_COMMANDS[moduleType];
  }

  /**
   * Sets the interval duration for sensor checks
   * @param duration - The duration in milliseconds between checks
   */
  setCheckIntervalDuration(duration: number) {
    if (this.checkIntervalDuration === duration) {
      return;
    }

    this.checkIntervalDuration = duration;
    // If already running, restart with new interval
    if (this.isRunning()) {
      this.stop();
      this.start();
    }
  }
}
